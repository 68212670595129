











import {Component, Vue} from 'vue-property-decorator';
import axios from 'axios';

@Component({
  components: {}
})
export default class References extends Vue {
  public references: any[] = [];

  mounted(): void {
    axios.get('/data/references.json')
        .then((response) => {
          this.references = response.data
        })
        .catch((error) => {
          console.log(error);
        });
  }
}
